(function ($) {
    // $(window).scroll(function () { // check if scroll event happened
    //     if ($(document).scrollTop() > 50) { // check if user scrolled more than 50 from top of the browser window
    //         $(".navbar-custom").addClass('scrolled')
    //         $(".dropdown-menu").addClass('scrolled')
    //     } else {
    //         $(".navbar-custom").removeClass('scrolled')
    //         $(".dropdown-menu").removeClass('scrolled')
    //     }
    // });

    $('[data-toggle="tooltip"]').tooltip()

})(jQuery);