(function ($) {

    'use strict';

    $('a.share').on('click', function (e) {
        e.preventDefault();
        var $this = $(this),
            u = encodeURIComponent(window.location.href),
            t = encodeURI(document.title),
            f = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=600';
        switch ($this.data('network')) {
            case 'facebook':
                return window.open('https://www.facebook.com/sharer.php?u=' + u + '&t=' + t, "Share with facebook", f);

            case 'google-plus':
                return window.open('https://plus.google.com/share?url=' + u, "Share with google", f);

            case 'twitter':
                return window.open('https://twitter.com/intent/tweet?text=' + t + '&url=' + u, "Share with twitter", f);

            default:
                return;
        }
    });

})(jQuery);