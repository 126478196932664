(function ($) {
    'use strict';

    var _carousel = $(".custom-carousel");
    if (_carousel.length) {
        _carousel.slick({
            arrows: false,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000
        });
        //_carousel.removeClass('loading')
    }

})(jQuery);