(function ($) {
    "use strict";

    $('#search-box-toggle').on('click', function (e) {
        $('#navbar').collapse('hide');
        $('#search-box-wrapper').toggleClass('hidden-xs')
    });

    $('#navbar').on('show.bs.collapse', function (e) {
        $('#search-box-toggle').collapse('hide');
        $('#search-box-wrapper').addClass('hidden-xs')
    });


    $('#search-box').autocomplete({
        serviceUrl: '/search',
        minChars: 3,
        maxHeight: 440,
        paramName: 'q',
        preventBadQueries: false,
        appendTo: $('.inner-addon'),
        onSearchStart: function () {
            $('.inner-addon .icon-search').addClass('hidden');
            $('.inner-addon .icon-spinner').removeClass('hidden');
        },
        onSearchComplete: function () {
            $('.inner-addon .icon-spinner').addClass('hidden');
            $('.inner-addon .icon-search').removeClass('hidden');
        },
        onSearchError: function (query, jqXHR, textStatus, errorThrown) {
            console.log(errorThrown);
        },
        onSelect: function (suggestion) {
            window.location = suggestion.data.href
        },
        formatResult: function (suggestion, currentValue) {
            return '<a href="' + suggestion.data.href + '"><div class="media">' +
                '<div class="media-left"><img class="media-object" src="' + suggestion.data.image + '"></div>' +
                '<div class="media-body"><h3 class="media-heading">' + suggestion.value + '</h3></div></div></a>';
        }

    });


}(jQuery));

