(function ($) {
    "use strict";

    var CinemaComponent = {
        _iFrameSetup: function (url) {
            var cinema = document.getElementById('cinema');
            while (cinema.firstChild) {
                cinema.removeChild(cinema.firstChild);
            }
            var iframe = document.createElement('iframe');
            iframe.setAttribute('allowFullScreen', '');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('scrolling', 'no');
            iframe.style.width = '100%';
            iframe.src = url;
            cinema.appendChild(iframe)
        }
    };


    $('.play').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.hasClass('active')) return;
        CinemaComponent._iFrameSetup($(this).attr('embedUrl'));
        $('.play').removeClass('active');
        $this.toggleClass('active')
    });


    // INITIAL SETUP
    var _initE = $('.tab-pane.active ul.episodes > li:first-child a');
    if (_initE.length) {
        CinemaComponent._iFrameSetup(_initE.attr('embedUrl'));
        _initE.addClass('active')
    }


}(jQuery));
