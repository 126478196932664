(function ($) {

    'use strict';

    var randomWidget = $(".random-widget");
    if (randomWidget.length) {
        randomWidget.slick({
            slidesToShow: 8,
            slidesToScroll: 4,
            centerPadding: '15px',
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 300,
                settings: "unslick"
            }]
        });
        randomWidget.removeClass('loading')
    }

})(jQuery);